@import "@/assets/scss/style.scss";

.product-images-modal {
    height: 100%;
    overflow: auto;
    margin: 0 5rem;
}

.product-images-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    max-height: 65vh;
    gap: 10px;
    overflow: auto;
}

::v-deep {
    .ant-modal {
        min-width: 30rem;
        max-width: 80vw;
    }
}
